import { template as template_b6f64fb031434b6a80f2d8e932ff50a2 } from "@ember/template-compiler";
const WelcomeHeader = template_b6f64fb031434b6a80f2d8e932ff50a2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
