import { template as template_7484b236c70b45a0bc05c4f0da041886 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7484b236c70b45a0bc05c4f0da041886(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
