import { template as template_1647c48628b24dd8aec8d8bc72b270ba } from "@ember/template-compiler";
const SidebarSectionMessage = template_1647c48628b24dd8aec8d8bc72b270ba(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
